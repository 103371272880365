import * as React from 'react';

import {
  Text,
  BlogImage,
  BlogInfographics,
  BlogLiterature,
} from '../../../components';

import { PageNew, SectionBlog } from '../../../fragments';

import infographic from '../../../images/educational-corner/blog8/infografika.png';
import openQuestions from '../../../images/educational-corner/blog8/Otvorena-pitanja.png';
import buildSentences from '../../../images/educational-corner/blog8/Prosirivanje-recenica.png';
import vocabularyGrowth from '../../../images/educational-corner/blog8/Razvoj-rjecnika.png';

import './index.css';

const EncouragingLanguageUnderstanding = () => {
  return (
    <PageNew
      title="KOKOLINGO – Poticanje Jezičnog Razumijevanja i Izražavanja"
      description="Važno je djetetu dati dovoljno vremena i što više poticati jezično
      razumijevanje i izražavanje spontano, svakodnevnim igrama i interakcijom s djetetom."
      headline="Poticanje jezičnog razumijevanja i izražavanja"
    >
      <Text as="p">
        Da bi dijete moglo uspješno komunicirati s osobama iz svoje okoline,
        mora imati sredstvo za komunikaciju. Sredstvo za komunikaciju kojim se
        koriste djeca i odrasli jest jezik. Jezik nam omogućuje da izrazimo
        svoje misli, želje i potrebe.
      </Text>
      <Text as="h2">Rani jezični razvoj</Text>
      <Text as="p">
        Djeca progovore oko prvog rođendana, no njihov se jezik razvija i prije
        pojave prve riječi. Da bi dijete moglo izgovoriti prvu riječ, mora
        razumjeti njezino značenje. Zbog toga je u djetetovoj ranoj dobi važno
        pratiti jezično razumijevanje.
      </Text>
      <Text as="p">
        <b>
          Jezično razumijevanje podrazumijeva da dijete razumije i slijedi naše
          upute.{' '}
        </b>
        Primjerice, donese nam traženi predmet, napravi određenu radnju (uzme
        papuče, opere ruke, stavi cipele, pije sok i sl.), uzme predmet koji smo
        mu pokazali. Jezično razumijevanje također podrazumijeva da dijete
        razumije pitanja i može dati adekvatan odgovor na njih. U početku su to
        pitanja DA/NE oblika. Primjerice, ako pitamo dijete želi li soka, dijete
        će reći “da” ili će kimnuti. Sazrijevanjem dijete razumije i pitanja
        TKO? ŠTO? GDJE? te može odgovoriti na njih govorom ili pokazivanjem
        prstom, odnosno pokazivanjem predmeta.
      </Text>
      <Text as="p">
        <b>
          Uz uredno jezično razumijevanje, važno je pratiti kako se dijete
          izražava; govori li ili ne, ako govori izražava li se riječima ili
          rečenicama.{' '}
        </b>
        Ako se izražava rečenicama, važno je da su rečenice smislene i
        gramatički točne. Zbog toga nam je prije same korekcije izgovora važno
        poticati jezično razumijevanje i izražavanje, odnosno povećati broj
        riječi koje dijete razumije i upotrebljava te povećati dužinu rečenica
        kojima se koristi.
      </Text>
      <Text as="h2">Kako poticati razvoj rječnika</Text>
      <Text as="p">
        <ul>
          <li>
            <b>Ponavljajte riječi u različitim situacijama.</b>
          </li>
        </ul>
        Za korištenje novim pojmovima potrebno je razumjeti njihovo značenje,
        stoga je važno da dijete pojmove iz svakodnevne okoline čuje u govoru
        odraslih. Kako bi dijete moglo zapamtiti nove riječi, treba ih često
        ponavljati u različitim situacijama. Ako je dijete kasnije progovorilo,
        znači da mu je potrebno više vremena za usvajanje novih riječi, a samim
        time i više ponavljanja.
        <b>
          {' '}
          Više ponavljanja ne znači da ćemo jednu riječi izgovoriti djetetu više
          puta odjednom, nego da ćemo je upotrebljavati u različitim
          situacijama.
        </b>
      </Text>
      <Text as="p">PRIMJER : Glagol BACITI</Text>
      <Text as="p">
        <ul className="listBullet">
          <li>
            <i>SITUACIJA 1:</i>
          </li>
          <li>
            <ul className="listBullet">
              <li>Igranje s loptom u sobi</li>
              <li>
                RODITELJ (dok djetetu baca): <i>Evo, sad ću ti baciti loptu!</i>
              </li>
              <li>
                RODITELJ (kad dijete vraća): <i>Baci loptu!</i>
              </li>
              <li>
                RODITELJ (kad je bacilo): <i>Bravo, bacio si loptu!</i>
              </li>
            </ul>
          </li>
        </ul>
        <ul className="listBullet">
          <li>
            <i>SITUACIJA 2:</i>
          </li>
          <li>
            <ul className="listBullet">
              <li>Bacanje smeća (primjerice nakon jedenja banane)</li>
              <li>
                RODITELJ:{' '}
                <i>
                  Gotovo, pojeli smo. Sada ćemo baciti koru. Koru bacamo u
                  smeće. 1, 2, 3, bacili smo koru.
                </i>
              </li>
            </ul>
          </li>
        </ul>
        <ul className="listBullet">
          <li>
            <i>SITUACIJA 3:</i>
          </li>
          <li>
            <ul className="listBullet">
              <li>Loptanje vani, loptanje s drugom osobom</li>
              <li>
                RODITELJ:{' '}
                <i>Pokaži tati kako smo bacali loptu. Tri, četiri, baci!</i>
              </li>
            </ul>
          </li>
        </ul>
      </Text>
      <Text as="p">
        <ul className="listBullet">
          <li>
            <ul className="listBullet">
              <li>
                Ponovite još jednom aktivnost, ali sada napravite stanku u kojoj
                djetetu dajete dovoljno vremena da ono nastavi rečenicu.
              </li>
              <li>
                RODITELJ: <i>Ajmo još jednom. Tri, četiri</i>…(stanka)… – ako
                dijete kaže BACI, pohvalimo ga, ponovimo za njim. Ako ne kaže
                ništa, kažemo umjesto njega. Nakon određenog broja ponavljanja,
                dijete će shvatiti što se traži od njega i ponovit će ciljanu
                riječ.
              </li>
            </ul>
          </li>
        </ul>
      </Text>
      <Text as="p">
        <ul>
          <li>
            <b>
              Imenujte predmete ili radnje kada dijete obrati pozornost na njih.
            </b>
          </li>
        </ul>
        Za usvajanje novih pojmova, osim ponavljanja, važna je vizualna potpora.
        To znači da će dijete lakše razumjeti i usvojiti novu riječ ako je
        povezana s trenutačnom radnjom, prisutnim predmetom ili zvukom.
      </Text>
      <Text as="p">
        Primjerice, ako dijete u parku vidi psa i pokaže ga, to je prilika da
        nauči više novih pojmova. Možete samo imenovati psa, čime dijete
        povezuje riječ “pas” s onim što je pokazalo. Ako uz to dodate ono što
        pas radi (npr. pas šeće) ili kako se pas glasa, osim riječi “pas”,
        dijete će imati priliku usvojiti tri umjesto jednog pojma.
      </Text>
      <Text as="h2">Kako pospješiti razumijevanje rečenica</Text>
      <Text as="p">
        Često djeca razumiju velik broj pojmova, no imaju teškoća u
        razumijevanju pitanja ili uputa, posebice ako su složenija. Ako
        primijetite da vam dijete ne odgovara, da vam daje pogrešan odgovor ili
        napravi nešto drugo, a ne ono što ste od njega tražili, ponovite
        pitanje, odnosno uputu. Ponovite je sporije uz naglašavanje ključnih
        riječi.
        <b>
          {' '}
          Možete uputu izreći kraće, preoblikovati je i gestom opisati što ste
          rekli.{' '}
        </b>
        Nadalje, možete tražiti od djeteta da potvrdi što ste rekli ili da ono
        preoblikuje što ste rekli.
        <ul className="listBullet">
          <li>PRIMJER</li>
          <li>
            <ul className="listBullet">
              <li>
                RODITELJ: <i>Molim te, idi do sobe i donesi jaknu.</i>
              </li>
              <li>Dijete ode, ali se ne vrati s jaknom.</li>
              <li>
                RODITELJ:{' '}
                <i>
                  Idemo van. Treba ti jakna (pokažite rukom kako biste odjenuli
                  jaknu). Uzmi jaknu.
                </i>
              </li>
            </ul>
          </li>
        </ul>
        <ul className="listBullet">
          <li>
            <ul className="listBullet">
              <li>
                Možete umjesto toga dati dio upute, a zatim dijete pitati
                pitanje, čime osim razumijevanja potičete i dijete na
                izražavanje.
              </li>
              <li>
                RODITELJ:{' '}
                <i>
                  Idemo van. Vani je hladno (pokažite kako je hladno). Što nam
                  treba?
                </i>
              </li>
              <li>
                DIJETE: <i>Jakna.</i>
              </li>
              <li>
                RODITELJ: <i>Tako je, treba nam jakna. Donesi jaknu.</i>
              </li>
            </ul>
          </li>
        </ul>
      </Text>
      <BlogImage
        imgSrc={vocabularyGrowth}
        imgAlt="Roditelj potice dijete da razvija rjecnik"
        imgTitle="Razvoj rjecnika"
      />
      <Text as="h2">Kako poticati jezično izražavanje</Text>
      <Text as="p">
        Kada djeca počinju govoriti, izražavaju se jednom riječju. U početku su
        to riječi mama, tata, a s vremenom se broj riječi povećava pa tako
        opisuju što rade oni ili drugi ili imenuju predmete iz okoline. Kako
        djeca sazrijevaju, tako i njihove misli, želje i potrebe postaju sve
        složenije. Tada im za izražavanje nisu dostatne riječi, nego počinju
        slagati prve rečenice. Djeca uče prema modelu, stoga im trebamo dati
        model kako stvarati jednostavne rečenice.
        <ul>
          <li>
            <b>Opisujte ono što dijete radi.</b>
          </li>
        </ul>
        Opisivanjem radnji umjesto djeteta pružate djetetu model kako da se ono
        izrazi o tome što mu je trenutačno predmet interesa. Osim što tako
        pokazujete djetetu kako opisati što radi te kako oblikovati rečenicu,
        dajete mu do znanja da obraćate pažnju na njega. Dijete će puno brže
        usvojiti nove pojmove, ali i nove rečenične oblike ako se tiču njegovih
        interesa.
      </Text>
      <Text as="p">
        <ul>
          <li>
            <b>
              Opisujte ono što rade drugi ukućani. Opisujte radnje u zajedničkoj
              igri.
            </b>
          </li>
        </ul>
        Kao i pri opisivanju djetetovih radnji, opisivanjem radnji ukućana ili
        igračaka pružate model za stvaranje jednostavnih rečenica. U ovim
        situacijama također obogaćujete djetetov rječnik i razumijevanje
        jednostavnih rečenica jer pomažete djetetu da poveže ono što vidi s
        rečenicom koju ste upotrijebili da to opišete.
      </Text>
      <Text as="p">
        <ul className="listBullet">
          <li>PRIMJER</li>
          <li>
            <ul className="listBullet">
              <li>Zajednička igra s medvjedićem</li>
              <li>
                RODITELJ.{' '}
                <i>
                  Medo se kupa. Ima puno vode. Medo pere uši. Pere kosu. Pere
                  noge. Kako medo miriše!
                </i>
              </li>
            </ul>
          </li>
        </ul>
      </Text>
      <Text as="p">
        <ul>
          <li>
            <b>Proširujte djetetove iskaze</b>
          </li>
        </ul>
        Ako se dijete starije od dvije godine i dalje izražava jednom riječju,
        potrebno je poticati razvoj rečenica. Osim opisivanjem radnji i predmeta
        iz okoline, to možemo učiniti tako da proširimo djetetove iskaze.
        Proširivanje iskaza znači da uz onu riječ koju je dijete reklo dodamo
        još jednu.
      </Text>
      <Text as="p">
        <ul className="listBullet">
          <li>PRIMJER 1:</li>
          <li>
            <ul className="listBullet">
              <li>
                Ako vam dijete pokaže autić i kaže: <i>„auto“</i>, dodajte riječ
                kojom ćete opisati auto:{' '}
                <i>
                  auto juri, voziš auto, veliki auto, brzi auto, crveni auto…
                </i>
              </li>
            </ul>
          </li>
          <li>PRIMJER 2:</li>
          <li>
            <ul className="listBullet">
              <li>
                DIJETE: <i>Sok!</i>
              </li>
              <li>
                RODITELJ: <i>Želiš sok?</i> ili <i>Još soka? Evo ti soka!</i>
              </li>
            </ul>
          </li>
        </ul>
        Ako je dijete starije i već se koristi kraćim rečenicama, također ćemo
        proširivati te rečenice, odnosno poticati ga da s vremenom samostalno
        počne upotrebljavati dulje rečenice.
      </Text>
      <Text as="p">
        <ul className="listBullet">
          <li>PRIMJER 3:</li>
          <li>
            <ul className="listBullet">
              <li>
                DIJETE: <i>Nosim torbu.</i>
              </li>
              <li>
                RODITELJ: <i>Nosiš tešku torbu! Nosiš torbu punu igračaka!</i>
              </li>
            </ul>
          </li>
        </ul>
      </Text>
      <BlogImage
        imgSrc={buildSentences}
        imgAlt="Roditelj potice dijete na pravilan izgovor tako daprosirivanjem recenica"
        imgTitle="Prosirivanje recenica"
      />
      <Text as="p">
        <ul>
          <li>
            <b>Postavljajte pitanja otvorenog tipa.</b>
          </li>
        </ul>
        Često djeci postavljamo pitanja kod kojih je očekivan odgovor DA ili NE
        (primjerice Želiš li jabuku?, Možeš li sam/sama?, Želiš li sok? i
        slično). Pitanja tog tipa nekad su nužna, no ne potiču dijete na jezično
        izražavanje jer zahtijevaju od njega samo da kaže DA ili NE, odnosno da
        kimne ili odmahne glavom. Umjesto toga, postavljajte pitanja koja
        zahtijevaju odabir ponuđenog. To su pitanja koja sadržavaju veznik ili,
        kao što je pitanje: <i>Želiš li čaj ili mlijeko</i>? Ovakvim oblikom
        pitanja djetetu dajemo model, odnosno riječi koje može ponoviti.
        Nadalje, stvaramo priliku u kojoj mora dati odgovor te ga tako potičemo
        na korištenje usvojenih riječi ili rečenica. Ujedno ovakvim oblikom
        pitanja potičemo jezično razumijevanje jer dijete mora razumjeti obje
        opcije da bi moglo napraviti odabir. Što su djeca starija, poželjno je
        koristiti i druge oblike pitanja kao što su{' '}
        <i>TKO? ŠTO? GDJE? ZAŠTO? KAKO?</i> jer zahtijevaju od djeteta da
        imenuje neki predmet ili radnju, objasni gdje se nešto nalazi ili opiše
        što se dogodilo. Korištenjem pitanja potičete dijete na izražavanje i
        vodite ga kroz prepričavanje priča i događaja.
      </Text>
      <BlogImage
        imgSrc={openQuestions}
        imgAlt="Roditelj djetetu postavlja otvoreno pitanje"
        imgTitle="Otvorena pitanja"
      />
      <Text as="h2">Poticanje jezičnih vještina korištenjem slikovnica</Text>
      <Text as="p">
        <b>
          Slikovnice su odličan način poticanja jezičnog razumijevanja i
          izražavanja zbog toga što djetetu pružaju vizualnu podršku,{' '}
        </b>
        odnosno pomažu u razumijevanju i upamćivanju novih riječi i rečenica,
        što je ključno kako bi ih dijete moglo proizvesti. Nadalje, u
        slikovnicama se ponekad nalaze likovi, predmeti i radnje s kojima se
        dijete možda ne susreće svakodnevno, čime se stvara prilika za
        obogaćivanje rječnika. Ako se u slikovnici koju zajedno čitate javljaju
        pojmovi s kojima se dijete svakodnevno susreće, to je poticaj za njega
        da se izrazi o onome što mu je poznato.
      </Text>
      <Text as="p">
        Kada dijete gleda u lik na slikovnici, opišite ga:{' '}
        <i>Pas. Pas skače. Pas ide vau vau. Pas ima mekanu dlaku.</i>
      </Text>
      <Text as="p">
        Tražite od djeteta da vam pokaže taj lik ili da vam pokaže što taj lik
        radi. Isto tako, možete pitati dijete što radi. Ako dijete odgovori
        jednom riječju, proširite to na dvije.
      </Text>
      <Text as="p">
        <ul className="listBullet">
          <li>PRIMJER:</li>
          <li>
            <ul className="listBullet">
              <li>
                DIJETE: <i>Skače.</i>
              </li>
              <li>
                RODITELJ: <i>Da, pas skače.</i>
              </li>
            </ul>
          </li>
        </ul>
      </Text>
      <Text as="h2">Savjet za kraj</Text>
      <Text as="p">
        Ovo su samo neke od strategija koje možete primijeniti u poticanju
        jezičnog razumijevanja i izražavanja u ranoj djetetovoj dobi. Uglavnom
        su to strategije kojima se roditelji koriste spontano, no važno ih je
        spomenuti jer su roditelji djece koja kasne u jezično-govornom razvoju
        često nesigurni u vezi s tim komuniciraju li pravilno sa svojom djecom.
        Može se dogoditi da dijete neće brzo krenuti s korištenjem novih riječi
        i rečenica unatoč primjeni ovih i drugih strategija. Dijete vjerojatno
        neće odmah ponoviti za vama kad mu prvi put pružite pravilan model. No,
        nakon većeg broja ponavljanja, javit će se i prvi djetetovi pokušaji
        ponavljanja.
        <b>
          {' '}
          Važno je djetetu dati dovoljno vremena i što više poticati jezično
          razumijevanje i izražavanje spontano, svakodnevnim igrama i
          interakcijom s djetetom.
        </b>
      </Text>
      <Text as="p">Maja Grden, mag. logoped.</Text>
      <BlogLiterature>
        <ul>
          <li>
            Matijević, K. (2019). Progovorimo lako - Pokaži mi kako. Zagreb:
            Planet Zoe.
          </li>
          <li>
            Rade, R. (2003). Poticanje ranog govorno-jezičnog razvoja :
            namijenjeno roditeljima djece usporena govorno-jezičnog razvoja.
            Zagreb: FoMa.
          </li>
          <li>
            <i>How does your child hear and talk?</i>.
            <a
              href="https://www.asha.org/public/speech/development/chart/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              https://www.asha.org/public/speech/development/chart/
            </a>
            , pristupljeno 12.12.2020.
          </li>
        </ul>
      </BlogLiterature>
      <BlogInfographics infographicSrc={infographic} />
      <SectionBlog />
    </PageNew>
  );
};

export default EncouragingLanguageUnderstanding;
